import React, { useContext, useEffect, useState } from "react";
import "./Style.css";
import {
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
} from "react-beautiful-dnd";
import {
  Task,
  deleteTask,
  taskCompleted,
  updateDueDate,
  updateTaskName,
} from "../../../Data/Task";

import { DateFormat } from "../../../Util/Date";

import { ThemeContext } from "../../../Context/Color";
import { useData } from "../../../Context/Data";

import Icon from "../../Atoms/Icon/Icon";
import Button from "../../Atoms/Button/Button";
import { useSnackbar } from "../../../Context/SnackbarProvider";
import closeNewTask from "../../../Util/closeNewTask";
import DoneButton from "../../Organizm/DoneButton/DoneButton";
import PopupMenu from "../PopupMenu/PopupMenu";
import { useOverlay } from "../../../Context/OverlayProvider";
import WarnDlg from "../../Organizm/WarnDlg/WarnDlg";
import RemindersRow from "../RemindersRow/RemindersRow";
import EditDlg from "../../Organizm/EditDlg/EditDlg";
import { DueDateReminder } from "../../../Data/DueDateReminder";
import { Timestamp } from "firebase/firestore";

interface TaskItemProps {
  item: Task;
  onTaskClick: (taskID: string) => void;
  showDescription: boolean;
  showReminders: boolean;
  index: number;
  isMobile: boolean;
  showListName: boolean;
  todayView: boolean;
  upcomingView: boolean;
  taskdone: boolean;
  onTaskCompleted: (done: boolean, taskID: string) => void;
  onMoveToTop: (taskID: string) => void;
  flagged: boolean;
  userID: string;
  onTaskFlag: Function;
  userTimeZone: string;
}

const getItemStyle = (
  isDragging: boolean,
  isHovered: boolean,
  isTempTask: boolean,
  draggedColor: string,
  hoverColor: string,
  draggableStyle: DraggingStyle | NotDraggingStyle | undefined
): React.CSSProperties => ({
  background: isDragging
    ? draggedColor
    : isHovered
    ? hoverColor
    : isTempTask
    ? "#FFE4B5"
    : "",
  cursor: "pointer",
  ...draggableStyle,
});

const TaskItem = (props: TaskItemProps) => {
  //Add to this compoent a right click handler
  const handleRightClick = (event: React.MouseEvent) => {
    event.preventDefault();

    setMouseX(event.clientX);
    setMouseY(event.clientY);
    // Add your right click handling logic here
    setShowPopup(true);
  };

  const { addSnackbar } = useSnackbar();

  const { addOverlay, removeOverlay } = useOverlay();

  const data = useData();

  const dateFormatter = new DateFormat();

  const [flagged, setFlagged] = useState(props.flagged);
  const [hideTask, setHideTask] = useState(false);

  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  const [showPoup, setShowPopup] = useState(false);

  const themeContext = useContext(ThemeContext);

  if (!themeContext) {
    throw new Error("ThemeContext not provided from a ThemeProvider component");
  }

  const [isHovered, setIsHovered] = useState(false);

  const isTaskDueToday = (): boolean => {
    const today = new Date();
    const taskDate = props.item.dueDate?.toDate();
    if (!taskDate) return false;
    today.setHours(0, 0, 0, 0);
    taskDate?.setHours(0, 0, 0, 0);
    return taskDate.getTime() === today.getTime();
  };

  const popupOptions = [
    {
      label: "Copy to clipboard",
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        if (event) event.stopPropagation();
        setShowPopup(false);
        setIsHovered(false);
        navigator.clipboard.writeText(props.item.name);
        addSnackbar("📋 Copied to clipboard");
      },
      color: themeContext?.textColor,
      iconComponent: <Icon copyIcon={true} />,
    },
  ];

  const hasTaskPassed = (): boolean => {
    const today = new Date();
    const taskDate = props.item.dueDate?.toDate();
    if (!taskDate) return true;
    if (!props.item.dueDateWithTime) {
      today.setHours(0, 0, 0, 0);
      taskDate?.setHours(0, 0, 0, 0);
    }
    return taskDate < today;
  };

  if (!props.taskdone) {
    if (!props.todayView && !props.upcomingView) {
      popupOptions.unshift({
        label: "Move to top",
        onClick: (event?: React.MouseEvent<HTMLElement>) => {
          if (event) event.stopPropagation();
          setShowPopup(false);
          setIsHovered(false);
          props.onMoveToTop(props.item.id!);

          addSnackbar("👆 Moved to top");
        },
        color: themeContext?.textColor,
        iconComponent: <Icon top={true} />,
      });
    }

    const taskDueToday = isTaskDueToday();

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const today = new Date();

    if (props.item.dueDateWithTime) {
      today.setHours(
        props.item.dueDate?.toDate().getHours() || 0, // Set hours from dueDate or default to 0
        props.item.dueDate?.toDate().getMinutes() || 0, // Set minutes from dueDate or default to 0
        0, // Set seconds to 0
        0 // Set milliseconds to 0
      );

      tomorrow.setHours(
        props.item.dueDate?.toDate().getHours() || 0, // Set hours from dueDate or default to 0
        props.item.dueDate?.toDate().getMinutes() || 0, // Set minutes from dueDate or default to 0
        0, // Set seconds to 0
        0 // Set milliseconds to 0
      );
    }

    popupOptions.unshift({
      label: taskDueToday ? "Due Tomorrow" : "Due Today",
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        if (event) event.stopPropagation();
        setShowPopup(false);
        setIsHovered(false);
        //  props.onMoveToTop(props.item.id!);
        console.log("h");
        updateDueDate(
          props.item.dueDateWithTime!,
          taskDueToday
            ? Timestamp.fromDate(tomorrow)
            : Timestamp.fromDate(today),
          props.userID,
          props.item.id!,
          new DueDateReminder()
        );
        addSnackbar(`Due Date set to ${taskDueToday ? "Tomorrow" : "Today"}`);
      },
      color: themeContext?.textColor,
      iconComponent: <Icon todayIcon={true} />,
    });

    popupOptions.push({
      label: "Edit Task Name",
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        if (event) event.stopPropagation();
        setShowPopup(false);
        setIsHovered(false);
        onEditTaskName();
      },
      color: themeContext?.textColor,
      iconComponent: <Icon editIcon={true} />,
    });

    popupOptions.push({
      label: "Delete",
      onClick: (event?: React.MouseEvent<HTMLElement>) => {
        if (event) event.stopPropagation();
        setShowPopup(false);
        setIsHovered(false);

        addOverlay(
          <WarnDlg
            onCancel={() => {
              removeOverlay();
            }}
            onApprove={() => {
              addSnackbar("Task deleted");
              deleteTask(props.userID, props.item.id!);
              removeOverlay();
            }}
            isMobile={props.isMobile}
            icon={<Icon deleteIcon={true} />}
            applyBtnRed={true}
            title="Are you sure?"
            subTitle={`"${props.item.name}" will be deleted forever.`}
            cancelBtnText="CANCEL"
            approveBtnText="YES, DELETE IT"
          />,
          {
            closeOnClickOutside: true,
            centerX: true,

            width: props.isMobile ? "" : "",
            marginTop: 100,
          },
          true
        );
      },
      color: themeContext?.dueDatePassed,
      iconComponent: <Icon deleteIcon={true} />,
    });
  }

  const taskHasDueDateReminders = () => {
    return (
      props.item.dueDate &&
      !hasTaskPassed() &&
      (props.item.dueDateReminderOnTime ||
        props.item.dueDateReminderMinutesBefore ||
        props.item.dueDateReminderHoursBefore ||
        props.item.dueDateReminderDaysBefore)
    );
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  useEffect(() => {
    setFlagged(props.flagged);
  }, [props.flagged]);

  const taskHasIcons = () => {
    if (props.taskdone) return false;
    if (props.showListName) return true;
    return (
      ((data.getTaskReminders(props.item.id!).length >= 1 ||
        taskHasDueDateReminders()) &&
        !props.showReminders) ||
      (props.item.description !== "" && !props.showDescription) ||
      props.item.dueDate!
    );
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const onClick = () => {
    if (!props.item.id || props.item.id === "temp_task") return;

    props.onTaskClick(props.item.id);
  };

  const onFlag = () => {
    setFlagged(!flagged);
    if (!flagged) setIsHovered(false);
    props.onTaskFlag(props.item.id, !flagged);
  };

  const onComplete = () => {
    setTimeout(() => {
      closeNewTask();

      setHideTask(true);
      if (props.item.id) taskCompleted(true, props.userID, props.item.id);
      addSnackbar("✌️ Completed", 8, "Undo", () => {
        if (props.item.id) taskCompleted(false, props.userID, props.item.id);
      });
    }, 200); // 1000ms = 1 second
  };

  const getListName = (listID: string) => {
    const projects = data.getLists();

    const project = projects.find((p) => p.id === listID);

    return project ? project.name : "";
  };

  const onEditTaskName = () => {
    addOverlay(
      <EditDlg
        header="Edit Task Name"
        placeHolder=""
        isMobile={props.isMobile}
        saveBtnText="Save"
        allowEmptyValue={false}
        maxLength={200}
        expandVertical={true}
        initialValue={props.item.name}
        cancelBtnText="Cancel"
        emailValidation={false}
        headerSize={24}
        onSave={(listName: string) => {
          addSnackbar("Task Name Updated ✅");

          updateTaskName(listName, props.userID, props.item.id!);
          removeOverlay();
        }}
        onCancel={() => {
          removeOverlay();
        }}
      />,
      {
        closeOnClickOutside: false,
        centerX: true,

        width: "100%",
        marginTop: 8,
      },
      true
    );
  };

  if (hideTask) return <></>;
  return (
    <Draggable
      key={props.item.id}
      draggableId={props.item.id!}
      isDragDisabled={props.taskdone || props.item.id === "temp_task"}
      index={props.index}
    >
      {(provided, snapshot): JSX.Element => (
        <div
          onContextMenu={handleRightClick}
          className="task"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            isHovered || showPoup,
            props.item.id === "temp_task",
            themeContext.taskDraggingColor,
            themeContext.taskHoverColor,
            provided.draggableProps.style
          )}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={onClick}
        >
          <div
            style={{
              display: "flex",
              paddingLeft: "10px",
              paddingTop: "14px",
              paddingBottom: "14px",
            }}
          >
            {!props.taskdone && <DoneButton onComplete={onComplete} />}
            {props.taskdone && (
              <Button
                actionTip="Back to list"
                actionTipPosition="left"
                textButtonColor={themeContext?.headerText}
                padding="4px"
                id="restore_task"
                label={""}
                iconComponent={
                  <Icon color={themeContext.textColor} restoreIcon={true} />
                }
                textButton={true}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!props.item.id) return;

                  props.onTaskCompleted(false, props.item.id);
                  taskCompleted(false, props.userID, props.item.id);
                  setHideTask(true);
                  addSnackbar("Task restored");
                }}
              ></Button>
            )}

            {props.taskdone && (
              <Button
                textButtonColor={themeContext?.headerText}
                padding="4px"
                actionTip="Copy to clipboard"
                actionTipPosition="bottom"
                id="copy_task"
                label={""}
                iconComponent={
                  <Icon color={themeContext.textColor} copyIcon={true} />
                }
                textButton={true}
                onClick={(e) => {
                  e.stopPropagation();
                  navigator.clipboard.writeText(props.item.name);
                  addSnackbar("📋 Copied to clipboard");
                }}
              ></Button>
            )}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10px",
              paddingTop: "14px",
              paddingBottom: "14px",

              flex: "1",
            }}
            className="task_details"
          >
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  flex: "1",

                  justifyContent: "flex-start",
                }}
                className="name_row"
              >
                <div
                  style={{
                    color:
                      props.item.id === "temp_task" || snapshot.isDragging
                        ? "black"
                        : themeContext.textColor,
                  }}
                  className="task_name"
                >
                  {props.item.name}
                </div>
              </div>
              {!props.taskdone && (
                <div className="task_flag">
                  <div
                    style={{
                      visibility: isHovered ? "visible" : "hidden",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      actionTip="Edit Task Name"
                      id="edit_task_name"
                      padding="0px"
                      marginRight={8}
                      label=""
                      textButton={true}
                      iconComponent={<Icon fontSize={24} editIcon={true} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        onEditTaskName();
                      }}
                    />
                    <Button
                      actionTip="Copy task name to clipboard"
                      id="copy_to_board"
                      padding="0px"
                      marginRight={8}
                      label=""
                      textButton={true}
                      iconComponent={<Icon fontSize={24} copyIcon={true} />}
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(props.item.name);
                        addSnackbar("📋 Copied to clipboard");
                      }}
                    />
                  </div>
                  {(props.item.priority === 1 || isHovered) && (
                    <Button
                      textButtonColor={themeContext?.headerText}
                      padding="0px"
                      id="task_flag"
                      actionTip={props.flagged ? "" : "Set priority: important"}
                      label={""}
                      iconComponent={
                        flagged ? (
                          <Icon
                            fontSize={24}
                            color={themeContext.flagColor}
                            flagIcon={true}
                          />
                        ) : (
                          <Icon
                            fontSize={24}
                            color={themeContext.textColor}
                            flagIconOutlined={true}
                          />
                        )
                      }
                      textButton={true}
                      onClick={(e) => {
                        e.stopPropagation();
                        onFlag();
                      }}
                    ></Button>
                  )}
                </div>
              )}
            </div>
            {!props.taskdone &&
              props.showDescription &&
              props.item.description !== "" && (
                <div
                  style={{ color: themeContext.taskDescriptionColor }}
                  className="task_description"
                >
                  {props.item.description}
                </div>
              )}

            {taskHasIcons() && (
              <div className="subs">
                {(data.getTaskReminders(props.item.id!).length >= 1 ||
                  taskHasDueDateReminders()) &&
                  !props.showReminders && (
                    <div className="sub">
                      <Icon
                        color={themeContext.textColor}
                        fontSize={18}
                        alarmIcon={true}
                      />
                    </div>
                  )}
                {props.showListName && (
                  <div
                    className="sub"
                    style={{
                      display: "flex",
                      color: themeContext.listNameColor,
                    }}
                  >
                    <div className="due_date_icon">
                      <Icon fontSize={18} listIcon={true} />
                    </div>
                    <div>{getListName(props.item.project!)}</div>
                  </div>
                )}
                {props.item.dueDate &&
                  ((!props.todayView && !props.upcomingView) ||
                    props.item.dueDateWithTime) && (
                    <div
                      className="sub"
                      style={{
                        color: dateFormatter.dateHasPassed(
                          props.item.dueDate.toDate(),
                          props.item.dueDateWithTime!
                        )
                          ? themeContext.dueDatePassed
                          : themeContext.textColor,
                      }}
                    >
                      <div className="duedate">
                        <div className="due_date_icon">
                          <Icon fontSize={18} dateIcon={true} />
                        </div>

                        {dateFormatter.formatDate(
                          props.item.dueDate.toDate(),
                          props.item.dueDateWithTime!
                        )}
                      </div>
                    </div>
                  )}
                {props.item.description !== "" && !props.showDescription && (
                  <Icon
                    color={themeContext.textColor}
                    fontSize={18}
                    detailsIcon={true}
                  />
                )}
              </div>
            )}
            {!props.taskdone &&
              props.showReminders &&
              (data.getTaskReminders(props.item.id!).length >= 1 ||
                taskHasDueDateReminders()) && (
                <RemindersRow
                  task={props.item}
                  userTimeZone={props.userTimeZone}
                  reminders={data.getTaskReminders(props.item.id!)}
                />
              )}
            {props.taskdone && (
              <div style={{ fontSize: "16px", color: themeContext.textColor }}>
                Completed{" "}
                {dateFormatter.formatDate(props.item.updated.toDate(), true)}
              </div>
            )}
          </div>
          <PopupMenu
            isOpen={showPoup}
            onMenuHide={() => {
              setShowPopup(false);
            }}
            backgroundColor={themeContext?.listBackground}
            rightClickMenu={true}
            mouseX={mouseX}
            mouseY={mouseY}
            iconComponent={<Icon fontSize={32} moreIcon={true} />}
            items={popupOptions}
            textSize={26}
          />
        </div>
      )}
    </Draggable>
  );
};

export default TaskItem;
